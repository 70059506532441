// eslint-disable-next-line import/prefer-default-export
export const portlandCities = [
  'Portland',
  'Beaverton',
  'Gladstone',
  'Gresham',
  'Happy Valley',
  'Lake Oswego',
  'Marylhurst',
  'Tigard',
  'Vancouver',
];
